import React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { SEO } from "../components/SEO";


const Imprint = () => {
  return (
    <Layout>
      <h1 className="font-extrabold text-4xl mt-8">Imprint</h1>
      <StaticImage src="../images/imprint.png"  />
      <p className="text-gray-500">
        According to §7 par. 1 TMG we are responsible for the content we create
        on this website. According to §§8 to 10 TMG we are not obliged to
        monitor foreign information or to do research on potential
        misuse.Obligations to delete or lock content according to current law
        remain untouched. In case some content violates the law, it will be
        removed immediately.
      </p>
      <h2 className="font-extrabold text-3xl mt-4">Content Liability</h2>
      <p className="text-gray-500">
        According to §7 par. 1 TMG we are responsible for the content we create
        on this website. According to §§8 to 10 TMG we are not obliged to
        monitor potential misuse. Obligations to delete or lock content
        according to current law remain untouched. In case some content violates
        the law, it will be removed immediately.
      </p>
      <h2 className="font-extrabold text-3xl mt-4">Liability for Links</h2>
      <p className="text-gray-500">
        Our offer contains links to external websites whose contents we cannot
        influence. Therefore we cannot be held liable for any contents outside
        of this website. Before we link from this site to other sites, we check
        for possible misuse. Illegal activities have not been detected when the
        link was created.
      </p>
      <p className="text-gray-500">
        A permanent control of the linked page is not appropriate if there are
        no hints to infringements. Once an infringement is communicated to us,
        we will remove these links immediately.
      </p>
      <h2 className="font-extrabold text-3xl mt-4">Copyright</h2>
      <p className="text-gray-500">
        The contents of this page fall under the German copyright law. The
        reproduction, editing, spread and use outside of the copyright law
        require the written consent of the respective author or creator.
        Downloads and copies of this site are only allowed for private and non
        commercial purposes. You are encouraged to share and link to the
        contents of this page on social media and on the web in general.
      </p>
      <p className="text-gray-500">
        If content on this website has not been created by the website’s
        operator, the copyright of third parties are respected. Third party
        content is marked explicitly. Should you recognise a copyright
        infringement, we kindly as you for a quick and informal hint. If content
        violates the law, it will be removed as soon as possible.
      </p>
      <h2 className="font-extrabold text-3xl mt-4">Privacy</h2>
      <p className="text-gray-500 mb-4">
        Please also take a look at our{" "}
        <Link className="text-blue-500" to="/privacy">
          privacy
        </Link>{" "}
        section to see how we process your data.
      </p>
    </Layout>
  );
};


export const Head = () => <SEO
  title="Imprint"
  pathname="/imprint"
  description="Imprint for the jangoebel.com website"
/>

export default Imprint;
